<template>
  <payment-terms-table :items="paymentTerms">
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('paymentterms')" />
        <v-spacer />
        <payment-terms-dialog
          @load="onLoad"
          @add="onAdd"
          @remove="onDelelete"
          :loading.sync="arLoading"
          v-model="paymentTermListId"
        />
      </v-toolbar>
    </template>
  </payment-terms-table>
</template>

<script lang="ts">
import { Customer } from "@planetadeleste/vue-mc-gw";
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import { Vue, Component, VModel } from "vue-property-decorator";

import PaymentTermsTable from "@/modules/paymentterms/components/PaymentTermsTable.vue";
import PaymentTermsDialog from "@/modules/paymentterms/components/PaymentTermsDialog.vue";

@Component({
  components: { PaymentTermsTable, PaymentTermsDialog },
})
export default class PaymentPaymentTermForm extends Vue {
  @VModel({ type: Object, default: () => new Customer() })
  obCustomer!: Customer;

  arPaymentTerms: PaymentTermData[] = [];
  arLoading: number[] = [];

  onLoad(arPaymentTerms: PaymentTermData[]) {
    this.arPaymentTerms = arPaymentTerms;
  }

  async onAdd(iPaymentTermId: number) {
    this.arLoading.push(iPaymentTermId);
    let obResponse = null;
    if (this.obCustomer.isNew()) {
      const paymentterms = this.obCustomer.get("paymentterms", []);
      const selectedPaymentterms = this.arPaymentTerms.find(
        ({ id }) => id === iPaymentTermId
      );
      if (
        paymentterms.filter(
          (_data: PaymentTermData) => _data.id === iPaymentTermId
        ).length === 0
      ) {
        paymentterms.push(selectedPaymentterms);
      }
      this.obCustomer.set("paymentterms", paymentterms);
    } else {
      obResponse = await this.obCustomer.addPaymentTerms(iPaymentTermId);
    }

    if (obResponse) {
      this.obCustomer.set("paymentterms", obResponse.getData().data);
    }

    const iIdx = this.arLoading.indexOf(iPaymentTermId);
    this.arLoading.splice(iIdx, 1);
  }

  async onDelelete(iPaymentTermId: number) {
    this.arLoading.push(iPaymentTermId);

    let obResponse = null;
    if (this.obCustomer.isNew()) {
      const paymentterms = this.obCustomer.get("paymentterms", []);
      const paymenttermIdx = paymentterms.indexOf(
        paymentterms.find(
          (_data: PaymentTermData) => _data.id === iPaymentTermId
        )
      );
      paymentterms.splice(paymenttermIdx, 1);
      this.obCustomer.set("paymentterms", paymentterms);
    } else {
      obResponse = await this.obCustomer.delPaymentTerms(iPaymentTermId);
    }

    if (obResponse) {
      this.obCustomer.set("paymentterms", obResponse.getData().data);
    }

    const iIdx = this.arLoading.indexOf(iPaymentTermId);
    this.arLoading.splice(iIdx, 1);
  }

  get paymentTerms() {
    return this.obCustomer.get("paymentterms", []);
  }

  get paymentTermListId() {
    return this.paymentTerms.length
      ? this.$_.map(this.paymentTerms, (obPaymentTerm) => obPaymentTerm.id)
      : [];
  }

  set paymentTermListId(arValue: number[]) {
    if (!this.arPaymentTerms.length) {
      return;
    }

    const arCustomerPaymentTerms = this.$_.filter(
      this.arPaymentTerms,
      (obPaymentTerm) => {
        return arValue.includes(obPaymentTerm.id);
      }
    );

    this.obCustomer.paymentterms = arCustomerPaymentTerms || [];
  }
}
</script>
