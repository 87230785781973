<template>
  <v-dialog
    scrollable
    max-width="800px"
    transition="dialog-top-transition"
    v-model="dialog"
  >
    <template #activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        {{ $t("edit") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("paymentterms") }}
      </v-card-title>
      <v-card-text>
        <payment-terms-table :items="obCollection.getModelList()" with-actions>
          <template #[`item.actions`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              :loading="loading.includes(item.id)"
              :dark="getDarken(item.id)"
              :color="getColor(item.id)"
              @click="toggle(item.id)"
            >
              <icon-check-circle size="1.5em" :stroke="2" />
            </v-btn>
          </template>
        </payment-terms-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false" depressed>
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { PaymentTerms } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import PaymentTermsTable from "@/modules/paymentterms/components/PaymentTermsTable.vue";

@Component({
  components: { PaymentTermsTable },
})
export default class PaymentTermsDialog extends Vue {
  @VModel({ type: Array, default: () => [] }) arPaymentTermsListId!: number[];
  @Prop({ type: Array, default: () => [] }) readonly loading!: number[];

  obCollection: PaymentTerms = new PaymentTerms();
  dialog = false;

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    await this.obCollection.list();

    this.$emit("load", this.obCollection.models);
  }

  toggle(iValue: number) {
    if (this.arPaymentTermsListId.includes(iValue)) {
      const iIndex = this.arPaymentTermsListId.indexOf(iValue);
      if (iIndex > -1) {
        this.arPaymentTermsListId.splice(iIndex, 1);
        this.$emit("remove", iValue);
      }
      return;
    }

    this.arPaymentTermsListId.push(iValue);
    this.$emit("add", iValue);
  }

  getColor(iValue: number) {
    return this.$_.isArray(this.arPaymentTermsListId) &&
      this.arPaymentTermsListId.includes(iValue)
      ? "green darken-2"
      : "blue-grey lighten-3";
  }

  getDarken(iValue: number): boolean {
    return (
      this.$_.isArray(this.arPaymentTermsListId) &&
      this.arPaymentTermsListId.includes(iValue)
    );
  }
}
</script>
